import React from 'react';
import FreelancerList from './Freelancerslist';

const App = () => {
 

  return (
    <div>
      <h1 style={{textAlign:'center'}}>Freelancer List</h1>
      <FreelancerList />
    </div>
  );
};
export default App;
